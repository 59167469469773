<template>
    <div class="bigBox">
        <div class="basicsName">
          <div class="basicsNames">
            <div
              class="basicsNamesTop"
              @click="navChange('all')"
              :class="[cardStates == 'all' ? 'basicsNamesTops' : '']"
            >
              全部
            </div>
            <div class="basicsNamesCen" v-show="cardStates == 'all'"></div>
          </div>
          <div class="basicsNames">
            <div
              class="basicsNamesTop"
              @click="navChange(0)"
              :class="[cardStates == 0 ? 'basicsNamesTops' : '']"
            >
              已激活
            </div>
            <div class="basicsNamesCen" v-show="cardStates == 0"></div>
          </div>
          <div class="basicsNames">
            <div
              class="basicsNamesTop"
              @click="navChange(1)"
              :class="[cardStates == 1 ? 'basicsNamesTops' : '']"
            >
              已过期
            </div>
            <div class="basicsNamesCen" v-show="cardStates == 1"></div>
          </div>
          <div class="basicsNames">
            <div
              class="basicsNamesTop"
              @click="navChange(2)"
              :class="[cardStates == 2 ? 'basicsNamesTops' : '']"
            >
              学习卡激活
            </div>
            <div class="basicsNamesCen" v-show="cardStates == 2"></div>
          </div>
        </div>
        <!-- 学习卡列表 -->
        <div class="contentBox" v-show="cardStates == 'all' || cardStates == 0 || cardStates == 1">
            <div 
                class="cardItem"
                v-for="item,index in list"
                :key="index"
            >
                <div class="itemBox">
                    <img class="itemImg" :src="item.img">
                    <div v-if="item.label == 'nomal'" class="label">已激活</div>
                    <div v-if="item.label == 'dead'" class="labelExpired">已过期</div>
                    <div class="contentItem">
                        <div class="contentTitle">{{item.title ? item.title : item.tiitle}}</div>
                        <div class="tips">{{ liveEnum[item.types] ? liveEnum[item.types] : '知识套餐' }}</div>
                        <div class="time">到期时间：{{ item.deadTimes }}</div>
                    </div>
                </div>
                <div class="itemBtn" @click="goDetail(item)">立即学习</div>
            </div>
        </div>
        <!-- 学习卡激活 -->
        <div class="activation" v-show="cardStates == 2">
            <div class="activationBox">
                <el-form >
                    <el-form-item label="卡号" label-width="60px">
                        <el-input class="inputItem" v-model="cardCode"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" label-width="60px">
                        <el-input class="inputItem" v-model="cardPass"></el-input>
                    </el-form-item>
                </el-form>
                <div class="errorTips" v-show="errTipsInfo">{{ errorTips }}</div>
                <!-- <div class="activationBtn" @click="submit">确认激活</div> -->
                <el-button :disabled="btnDisabled" class="activationBtn" @click="submit">确认激活</el-button>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            width="360px"
            :before-close="handleClose"
        >
        
            <div class="dialogBox">
                <img class="dialogImg" src="@/assets/img/Home/Group 465.png">
                <div class="dialogTips">激活成功！</div>
                <div class="className">{{activeStudyCardtInfo ? activeStudyCardtInfo.productName : ""}}</div>
                <div class="time">到期时间：{{activeStudyCardtInfo ? activeStudyCardtInfo.deadTime : ''}}</div>
                <div class="dialogBtn" @click="handleClose">确定</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getActStudyProductList,
        activeStudyCardt
    } from "@/api/home.js";
    import { liveEnum }from '@/api/emun.js'
    import Vue from "vue";
    import { getInfo } from "@/api/cookies";
    export default {
        data() {
            return {
                cardStates: "all",
                cardCode: '', // 卡号
                cardPass: '', // 卡密
                dialogVisible: false,
                activeStudyCardtInfo: null, // 激活返回状态  是否成功
                errTipsInfo: false,
                nomalProdList: [], // 已激活列表
                deadProdList: [], // 已过期列表
                list: [], // 渲染的列表
                liveEnum: null,
                errorTips: null,
                btnDisabled: false
            }
        },
        created() {
            this.liveEnum = liveEnum
        },
        mounted() {
            this.getActStudyProductList()
        },
        methods: {
            navChange(val) {
                this.list = []
                let arr = []
                if (val == 'all') {
                    if (this.nomalProdList.length > 0) {
                        arr.push(...this.nomalProdList)
                    }
                    if (this.deadProdList.length > 0) {
                        arr.push(...this.deadProdList)
                    }
                } else if (val == 0) {
                    if (this.nomalProdList.length > 0) {
                        arr.push(...this.nomalProdList)
                    } else {
                        this.list = []
                    }
                } else if (val == 1) {
                    if (this.deadProdList.length > 0) {
                        arr.push(...this.deadProdList)
                    } else {
                        this.list = []
                    }
                }
                this.list = arr
                
                this.cardStates = val;
                this.pageSize = 8;
                this.pageNum = 1;
            },
            /* 学习卡下套餐列表 */
            getActStudyProductList() {
                let data = {
                    userId: getInfo().id
                }
                getActStudyProductList(data).then(res => {
                    
                    if (res.code == 0 && res.msg) {
                        this.nomalProdList = res.msg.nomalProdList
                        this.deadProdList = res.msg.deadProdList
                        this.list.push(...res.msg.nomalProdList, ...res.msg.deadProdList)
                    }
                })
            },
            /* 确认激活 */
            submit() {
                this.btnDisabled = true
                let data = {
                    cardCode: this.cardCode,
                    cardPass: this.cardPass
                }
                activeStudyCardt(data).then(res => {
                    if (res.code == 0) {
                        this.errTipsInfo = false
                        this.activeStudyCardtInfo = res.msg
                        this.dialogVisible = true
                        this.getActStudyProductList()
                        this.btnDisabled = false
                    } else {
                        this.errorTips = res.msg
                        this.errTipsInfo = true
                        this.btnDisabled = false
                    }
                })
            },
            goDetail(item) {
                
                let type = null
                if (item.types == 1) {
                    type = 2
                } else if (item.types == 2) {
                    type = 3
                } else if (item.types == 3) {
                    type = 7
                } else if (item.types == 4) {
                    type = 8
                } else if (item.types == 5) {
                    type = 10
                }
                if (item.type != 0) {
                    Vue.prototype.goTypeDetail(type, item.id, undefined, undefined, undefined, undefined, item.cardCode)
                } else {
                    if (item.productLine == '3') {
                        this.$router.push({
                            path: `/VIP/VIPClassxq?id=${item.id}&cardCode=${item.cardCode}`,
                        })
                    } else {
                        this.$router.push({
                            path: `/typeclassxq?id=${item.id}&cardCode=${item.cardCode}`,
                        })
                    }
                }

            },
            /* 关闭弹窗回调 */
            handleClose() {
                this.dialogVisible = false
            }
        },
        
    }
</script>

<style lang="less" scoped>
    .bigBox{
        width: 100%;
        background-color: #ffffff;
        border-radius: 8px;
        padding-top: 20px;
        .basicsName {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            padding: 0px 32px;
            border-bottom: 1px solid #eee;
            .basicsNames {
                height: 100%;
                margin-right: 41px;
                cursor: pointer;
                .basicsNamesTop {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }
                .basicsNamesTops {
                    color: #FF5E51;
                }
                .basicsNamesCen {
                    width: 24px;
                    height: 2px;
                    margin: 14px auto 0px;
                    background: #ef0d0d;
                    border-radius: 1px;
                }
            }
        }
        .contentBox{
            width: 100%;
            padding: 40px;
            .cardItem{
                height: 188px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #EEEEEE;
                .itemBox{
                    display: flex;
                    position: relative;
                    .itemImg{
                        width: 192px;
                        height: 108px;
                        border-radius: 8px;
                        margin-right: 12px;
                    }
                    .contentItem{
                        .contentTitle{
                            font-size: 18px;
                            color: #333333;
                        }
                        .tips{
                            width: 64px;
                            height: 20px;
                            font-size: 12px;
                            color: #FF7B1C;
                            text-align: center;
                            line-height: 20px;
                            border-radius: 2px;
                            margin-bottom: 38px;
                            margin-top: 8px;
                            background-color: #FFF2E9;
                        }
                        .time{
                            font-size: 14px;
                            color: #999999;
                        }
                    }
                    .label{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 62px;
                        height: 24px;
                        background-color: #02DDB7;
                        border-radius: 8px 0 8px 0;
                        color: #ffffff;
                        font-size: 14px;
                        text-align: center;
                        line-height: 24px;
                    }
                    .labelExpired{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 62px;
                        height: 24px;
                        background-color: #9FA4AD;
                        border-radius: 8px 0 8px 0;
                        color: #ffffff;
                        font-size: 14px;
                        text-align: center;
                        line-height: 24px;
                    }
                }
                .itemBtn{
                    width: 88px;
                    height: 30px;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #ffffff;
                    text-align: center;
                    line-height: 30px;
                    background-color: #FF5E51;
                    cursor: pointer;
                }
            }
        }
        .activation{
            width: 100%;
            height: 430px;
            display: flex;
            align-items: center;
            justify-content: center;
            .activationBox{
                width: 550px;
                height: 300px;
                /* display: flex;
                flex-direction: column;
                align-items: center; */
                .activationBtn{
                    margin-left: 60px;
                    width: 170px;
                    height: 48px;
                    border-radius: 4px;
                    background-color: #FF5E51;
                    color: #ffffff;
                    text-align: center;
                }
                .inputItem{
                    width: 480px;
                }
                .errorTips{
                    color: #F33232;
                    font-size: 14px;
                    margin-left: 60px;
                    margin-bottom: 40px;
                }
            }
        }
    }
    .dialogBox{
        min-height: 306px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        .dialogImg{
            width: 64px;
            height: 64px;
        }
        .className{
            font-size: 18px;
            color: #333333;
        }
        .time{
            color: #999999;
            font-size: 14px;
        }
        .dialogTips{
            color: #333333;
            margin-bottom: 32px;
        }
        .dialogBtn{
            width: 80px;
            height: 40px;
            border-radius: 4px;
            border: solid 1px #FF5E51;
            text-align: center;
            line-height: 40px;
            margin-top: 32px;
            color: #FF5E51;
            cursor: pointer;
        }
    }
</style>